import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";

function SendHomeWorkMsgPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    list: (inData: any) => { },
    daily_report_arr: [],
    ...props
  };

  const [send_msg_data, set_send_msg_data] = useState<any>({
    hello_msg: strFunc.get_storage("send_home_work_hello_msg",""),
  });

  useEffect(() => {
    if (myProps.daily_report_arr.length == 0) {
      alert("선택이 없습니다.");
      myProps.closePopup();
    }
  }, []);

  const go_send_home_work_msg_of_daily_report_by_ajax = (inData: any) => {
    let opt_obj = {
      report_data_arr: myProps.daily_report_arr,
      hello_msg: send_msg_data.hello_msg,
      ...inData
    };
    //보고서 모으기
    let report_data_arr: any = opt_obj.report_data_arr;
    if (report_data_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }

    if (!confirm("학부모에게 숙제메세지를 보내시겠습니까?")) {
      return false;
    }

    let daily_pri_val_arr: any = [];
    for (let i = 0; i < opt_obj.report_data_arr.length; i++) {
      let tmp_row = opt_obj.report_data_arr[i];
      daily_pri_val_arr.push(tmp_row["a_ymd"] + "," + tmp_row["a_seq"]);
    }

    let w_report_form_json = {
      "daily_pri_val_arr": daily_pri_val_arr,
      "hello_msg": opt_obj["hello_msg"],
    };
    
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/daily_report/send_home_work_msg', w_report_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("숙제메세지를 보냈습니다.");
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <h1 className="text-center">숙제메세지 보내기</h1>
      <div>
        선택된 일일보고서 : {myProps.daily_report_arr.length}건
      </div>
      <div className="write_table_small">
        <table>
          <colgroup>
            <col width="30%" />
            <col width="70%" />
          </colgroup>
          <tbody>
            <tr>
              <th>인사말</th>
              <td>
                <input type="text" className="row-input" value={send_msg_data.hello_msg}
                  onChange={(e) => {
                    set_send_msg_data({
                      ...send_msg_data,
                      hello_msg: e.target.value
                    });
                    strFunc.set_storage("send_home_work_hello_msg", e.target.value);
                  }} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="text-center mt-1">
        <button className="btn btn-dark" onClick={() => {
          go_send_home_work_msg_of_daily_report_by_ajax({});
        }}>메세지 보내기</button>
        <button className="btn btn-dark ml-2" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
};
export default SendHomeWorkMsgPopup;