function SectionStudyListArea(props:any){
   let myProps={
    section_arr:[],
    study_arr:[],
    ...props
  };
  let section_arr=myProps.section_arr;
  let study_arr=myProps.study_arr;
  
  const get_section_tags=()=>{
    let rs_tags=section_arr.map((item:any,idx:number)=>{
      let idx_num=idx+1;
      return (
        <div key={idx} className="mt-2" style={{lineHeight:"21px"}}>
          <span style={{color:"#666666",fontSize:"16px",fontWeight:"bold"}}>
            {idx_num}. {item.a_title}
          </span>
          <div className="pl-2">
            {get_study_list_tags({section_info:item})}
          </div>
        </div>
      );
    });

    return rs_tags;
  };

  const get_study_list_tags=(inData:any)=>{
    let opt_obj={
      section_info:{},
      ...inData
    };
    let row_study_arr:any=[];
    for(let i=0;i<study_arr.length;i++){
      let item=study_arr[i];
      if(item.a_section_seq==opt_obj.section_info.a_seq){
        row_study_arr.push(item);
      }
    }

    let rs_tags=row_study_arr.map((item:any,idx:number)=>{
      let idx_num=idx+1;
      return (
        <div key={idx} style={{fontSize:"15px",lineHeight:"21px"}}>
          {idx_num}. {item.a_title} 
          {item.a_content} 
          <span className="ml-1">({item.a_amount_num}{item.a_amount_sort})</span>
        </div>
      );
    });

    return rs_tags;
  };

  return (
  <div>
    {get_section_tags()}
  </div>
  );
};
export default SectionStudyListArea;