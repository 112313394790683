import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import DateFunc from '@/lib/lyg/date_func';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import search_ico_img from "@/img/ico/search2.png";
import strFunc from '@/lib/lyg/string';
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";

const StuSearchList = forwardRef((props: any, ref) => {
  const myProps = {
    list: (inOptObj: any) => { },
    set_content_tab: (inData: any) => { },
    ...props
  };

  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_grade: ["student"],
    "s_class_seq": "",
    "s_stu_grade": "",
    "s_stu_state": "now",
    s_seq: [],
    select_stu_seq: "",
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_addon_counsel_todo_tot: "1",
    s_addon_counsel_start_date: DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', -1), "Y-m-01"),
    s_addon_counsel_end_date: DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', +1), "Y-m-t"),
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  const [lectrue_select_arr, set_lectrue_select_arr] = useState<any>([]);

  const search_time_out_ref=useRef<any>(null);

  useEffect(() => {
    get_lecture_select_arr();
  }, []);

  const go_search_list=(inData:any)=>{
    if(search_time_out_ref.current){
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current=setTimeout(()=>{
      list(inData);
    },200);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    setListOpt({ ...listOpt, [name]: value });
    go_search_list({ now_page: 1, [name]: value });
  };

  const get_lecture_select_arr = () => {
    let class_list_form = {
      "s_state": "ing",
      "order_id": "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', class_list_form, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lectrue_select_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const list = (inOptObj: any) => {
    let tmp_list_opt={...listOpt,...inOptObj};
    setListOpt(tmp_list_opt);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          if (listAggridRef.current) {
            listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
            //select_stu_seq
            if (!strFunc.is_empty(inOptObj["select_stu_seq"])) {
              setTimeout(() => {
                let listNodes = listAggridRef.current.getNodeRows();
                for (let i = 0; i < listNodes.length; i++) {
                  let node = listNodes[i];
                  if (inOptObj["select_stu_seq"] == node.data["a_seq"]) {
                    listNodes[i].setSelected(true);
                  } else {
                    listNodes[i].setSelected(false);
                  }
                }
              }, 100);
            }
          }
        } else {

        }
      });
  };

  const setSelectFalseAll = () => {
    listAggridRef.current.setSelectFalseAll();
    myProps.list({
      "s_class_seq_arr": [],
      "s_stu_user_seq": "",
      "s_stu_info_arr": [],
    });
  };

  const cellClickedListener = (params: any) => {
    var key = params.colDef.field;
    if (key == "a_name") {

    }
  }

  const removeSelectStu = (stu_seq: any) => {
    let all_node_rows = listAggridRef.current.getNodeRows();
    for (let i = 0; i < all_node_rows.length; i++) {
      let tmp_seq = all_node_rows[i].data["a_seq"];
      if (tmp_seq == stu_seq) {
        all_node_rows[i].setSelected(false);
      }
    }
    setTimeout(() => {
      onSelectionChanged({});
    }, 100);
  };

  const set_height = (height: any) => {
    if (listAggridRef.current) {
      listAggridRef.current.setHeight(height);
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list, setSelectFalseAll, removeSelectStu, set_height
  }));


  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if (key == "a_user_name") {
      render_str += " " + params.data["a_stu_total_code"];
    }else if (key == "row_view_manage") {

    } else if (key == "row_view_class_name") {
      if (params.data.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.data.class_arr.length; i++) {
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_counsel_cnt") {
      if (params.data.ctodo_tot_info) {
        let tot_span_style: any = {
          display: "inline-block",
          textAlign: "center",
          lineHeight: "26px",
          height: "26px",
          padding: "0px 8px",
          background: "gray",
          color: "#fff",
          borderRadius: "5px"
        };
        if (params.data.ctodo_tot_info["tot"] > 0) {
          tot_span_style["background"] = "#db6c58";
          if (params.data.ctodo_tot_info["success_tot"] > 0) {
            tot_span_style["background"] = "#eab358";
            if (params.data.ctodo_tot_info["tot"] == params.data.ctodo_tot_info["success_tot"]) {
              tot_span_style["background"] = "#a2b854";
            }
          }
        }
        render_str = (
          <div style={tot_span_style}>
            {params.data.ctodo_tot_info["success_tot"] + "/" + params.data.ctodo_tot_info["tot"]}
          </div>
        );
      }
    } else if (key == "row_view_school_grade") {
      let row_grade_str = params.data.a_stu_grade;
      if (xColumnArr.select_arr.a_stu_grade) {
        for (let i = 0; i < xColumnArr.select_arr.a_stu_grade.length; i++) {
          if (xColumnArr.select_arr.a_stu_grade[i]["value"] == params.data.a_stu_grade) {
            row_grade_str = xColumnArr.select_arr.a_stu_grade[i]["text"];
          }
        }
      }
      let row_school_name = strFunc.cut_str(params.data.a_school_name, 2, "");
      render_str = row_school_name + " " + row_grade_str;
    }
    return render_str;
  }, []);

  const onSelectionChanged = (event: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    let select_seq_arr = [];
    if (selected_row) {
      for (let i = 0; i < selected_row.length; i++) {
        select_seq_arr.push(selected_row[i]["a_seq"]);
      }
    }

    myProps.list({
      "s_class_seq_arr": [],
      "s_stu_user_seq": select_seq_arr,
      "s_stu_info_arr": selected_row,
    });
  };


  return (
    <div>
      <div className="relative" >
        <div className="relative p-3 text-center" style={{ lineHeight: "20px" }}>
          <SearchLectureSelect
            name="s_stu_lecture_seq"
            value={listOpt.s_stu_lecture_seq}
            on_change_input={(inData: any) => {
              let name = inData.name;
              let value = inData.value;
              list({ now_page: 1, [name]: value });
            }}
            wrap_style={{ width: 90, display: "inline-block" }}
            select_title_style={{ lineHeight: "28px" }}
          ></SearchLectureSelect>
          <select name="s_stu_grade" className="search-input ml-1" onChange={(e: any) => {
            list({
              "now_page": "1",
              "s_stu_grade": e.target.value
            });
          }}
            style={{ height: 30, width: 70 }} >
            <option value="">학년</option>
            <option value="elementary">초</option>
            <option value="middle">중</option>
            <option value="high">고</option>
            {xColumnArr.select_arr.a_stu_grade?.map((item: any, idx: number) => {
              return (
                <option key={idx} value={item.value}>{item.text}</option>
              );
            })}
          </select>
          <span className="basic_search_item_book_mark"
            onClick={() => {
              let change_s_stu_bookmark = listOpt.s_stu_bookmark;
              if (change_s_stu_bookmark == "1") {
                change_s_stu_bookmark = "";
              } else {
                change_s_stu_bookmark = "1";
              }
              strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
              list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
            }}>
            {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
          </span>
          <input type="text" className="search-input ml-1" name="s_search_text"
            style={{ width: 50, padding: 4 }} placeholder="이름" value={listOpt.s_search_text}
            onChange={handleInputChange} />
          <button className="search_list_btn"
            onClick={() => { list({ "now_page": 1 }); }} >
            <img src={search_ico_img} style={{ height: 20 }} />
          </button>
          {(listOpt.s_seq && listOpt.s_seq.length > 0) &&
            <button className="btn-s btn-red ml-1"
              style={{ height: 30 }}
              onClick={() => {
                list({ "now_page": "1", "s_seq": [], "select_stu_seq": "" });
                myProps.set_content_tab("calendar");
              }}>X</button>
          }
        </div>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: false,
          is_add_checkbox: false,
          floatingFilter: false,
          rowMultiSelectWithClick: true,
          height: "60vh",
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
          onSelectionChanged: onSelectionChanged
        }}
      ></ListAggrid>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        onChangeNumPerPage={(num_per_page:number)=>{list({now_page:1,num_per_page:num_per_page});}}></Paging>
    </div>
  );
});

export default StuSearchList;
