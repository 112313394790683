import SectionStudyListArea from "./area/section_study_list";

function RightContentArea(props:any){
   let myProps={
    right_content_info:{},
    ...props
  };
  let default_info={
    "a_seq": "",
    "a_subject_sort": "",
    "a_subject_main_seq": "",
    "a_subject_seq": "",
    "a_title": "",
    "a_answer_link_url": "",
    "a_open_group": "",
    "a_is_progress": "",
    "a_is_mapping": "",
    "subject_title":"",
    subject_info:{},
    section_arr:[],
    study_arr:[],
  };
  let info={
    ...default_info,
    ...myProps.right_content_info
  };

  return (
  <div>
    <div className="write_table_small">
      <table>
        <colgroup>
          <col width={"20%"}></col>
          <col width={"30%"}></col>
          <col width={"20%"}></col>
          <col width={"30%"}></col>
        </colgroup>
        <tbody>
          <tr>
            <th>과목</th>
            <td>{info.a_subject_sort}</td>
            <th>구분</th>
            <td>{info.subject_title}</td>
          </tr>
          <tr>
            <th>제목</th>
            <td colSpan={3}>
              {info.a_title}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="mt-1">
      <SectionStudyListArea
        section_arr={info.section_arr}
        study_arr={info.study_arr}
      ></SectionStudyListArea>
    </div>
  </div>
  );
};
export default RightContentArea;