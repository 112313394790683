import { lazy } from 'react';
import TodoStuCardListPage from "@/pages/comp/plan/todo/stu_card_list";
import PlanCalendar from "@/pages/comp/plan/calendar";
import VideoCategoryList from "@/pages/comp/plan/video/category/list";
import VideoCorseList from "@/pages/comp/plan/video/corse/list";
import VideoRequestList from "@/pages/comp/plan/video/request/list";
import VideoUseDetailListPage from "@/pages/comp/plan/video/history/use_detail/list";
import VideoShareCorseList from "@/pages/comp/plan/video/corse_share/list";

import StuTodoList from "@/pages/comp/plan/stu_todo_history/list";
import StudyList from "@/pages/comp/plan/study/study/list";
import StudySubjectList from "@/pages/comp/plan/study/subject/list";
import ShareList from "@/pages/comp/plan/study/share/list";
import StudyStuProgressPage from "@/pages/comp/plan/study/stu_progress/manage";
import CoolenMovePage from "@/pages/comp/plan/coolen/move";
const CoolenLoginIframe = lazy(() => import('@/pages/comp/plan/coolen/popup/login_coolen/frame/login_frame'));

import RoutineList from "@/pages/comp/plan/routine/list";
import PlanMakeTodo from "@/pages/comp/plan/todo/make_todo";
import PlanMakeTodoBack from "@/pages/comp/plan/todo/backup/make_todo";

const MathPlanBasicMakeList = lazy(() => import('@/pages/comp/plan_math/math_basic/make_list'));
const MathProgramLinkPage = lazy(() => import('@/pages/comp/plan_math/program/link_page'));

import TeaStuTodoListPage from "@/pages/comp/tea/tstu_todo/list";

const routerArr:object[] = [
  /* 맞춤플랜 */
  {
    path: "/comp/plan/todo/stu_card_list",
    element: <TodoStuCardListPage></TodoStuCardListPage>,
  },
  {
    path: "/comp/plan/calendar",
    element: <PlanCalendar></PlanCalendar>,
  },
  {
    path: "/comp/plan/video/category/list",
    element: <VideoCategoryList></VideoCategoryList>,
  },
  {
    path: "/comp/plan/video/corse/list",
    element: <VideoCorseList></VideoCorseList>,
  },
  {
    path: "/comp/plan/video/request/list",
    element: <VideoRequestList></VideoRequestList>,
  },
  {
    path: "/comp/plan/video/use_detail_history/list",
    element: <VideoUseDetailListPage></VideoUseDetailListPage>,
  },
  {
    path: "/comp/plan/video/corse_share/list",
    element: <VideoShareCorseList></VideoShareCorseList>,
  },

  {
    path: "/comp/plan/todo/stu_todo/list",
    element: <StuTodoList></StuTodoList>,
  },
  {
    path: "/comp/plan/study/study/list",
    element: <StudyList></StudyList>,
  },
  {
    path: "/comp/plan/study/subject/list",
    element: <StudySubjectList></StudySubjectList>,
  },
  {
    path: "/comp/plan/study/share/list",
    element: <ShareList></ShareList>,
  },
  {
    path: "/comp/plan/study/stu_progress/manage",
    element: <StudyStuProgressPage></StudyStuProgressPage>,
  },
  {
    path: "/comp/plan/coolen/move_page",
    element: <CoolenMovePage></CoolenMovePage>,
  },
  {
    path: "/comp/plan/coolen/iframe/login_iframe",
    element: <CoolenLoginIframe></CoolenLoginIframe>,
  },
  {
    path: "/comp/plan/study/routine/list",
    element: <RoutineList></RoutineList>,
  },
  {
    path: "/comp/plan/todo/make_todo",
    element: <PlanMakeTodo></PlanMakeTodo>,
  },
  {
    path: "/comp/plan/todo/make_todo_back",
    element: <PlanMakeTodoBack></PlanMakeTodoBack>,
  },
  {
    path: "/comp/plan_math/basic/make_list",
    element: <MathPlanBasicMakeList></MathPlanBasicMakeList>,
  },
  {
    path: "/comp/plan_math/program/link_page",
    element: <MathProgramLinkPage></MathProgramLinkPage>,
  },
  {
    path: "/comp/tea/tstu_todo/list",
    element: <TeaStuTodoListPage></TeaStuTodoListPage>,
  },
];

export default routerArr;