import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import FilePieceArea from "@/pages/comp/basic/file/frame/piece/file";
import ManageButtonArea from "./area/manage_btns";
import Style from "./css/style.module.css";

function WriteDetailArea(props: any) {
  let myProps = {
    stu_info: {},
    map_detail_info: null,
    section_study_name: {
      section_name: "",
      unit_name: "",
    },
    callback_refresh_data: (inData: any) => { },
    is_view_top_info: false,
    go_back_page: () => { },
    back_btn_text: "닫기",
    ...props
  };
  let stu_info = myProps.stu_info;
  let section_study_name = myProps.section_study_name;
  let map_detail_info = myProps.map_detail_info;

  let user = useSelector((state: any) => state.user);

  let select_a_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  let now_ymd = DateFunc.get_date_format(new Date(), "Y-m-d");
  let default_map_detail_info = {
    a_study_title_seq: "",
    a_stu_seq: "",
    a_seq: "",
    a_date: now_ymd,
    a_section_name: section_study_name.section_name,
    a_unit_name: section_study_name.unit_name,
    a_is_success: "",
    a_success_date: "",
    a_audio_record_msg: "",
    a_research_word: "",
    a_concept_section: "",
    a_concept_unit: "",
    a_cornell_note: "",
    a_memorize: "",
    a_mind_map: "",
    a_matter_solve: "",
    a_test_score: "",
    a_wrong_answer: "",
    map_info: null,
    user_info: null,
  };
  const [detail_info, set_detail_info] = useState({
    ...default_map_detail_info,
    ...map_detail_info
  });
  const [detail_file_arr, set_detail_file_arr] = useState<any>([]);
  let map_detail_par_id = "study_map_detail";
  let map_detail_pri_val = detail_info["a_study_title_seq"] + "," + detail_info["a_stu_seq"] + "," + detail_info["a_seq"];
  let default_file_row_data = {
    a_comp_seq: user.comp_seq,
    a_par_id: map_detail_par_id,
    a_par_seq: map_detail_pri_val,
    a_sort1: "audio",
    a_sort2: "menu1",
  };

  useEffect(() => {
    if (strFunc.is_empty(detail_info["a_seq"])) {
      go_save_map_detail_by_ajax({ is_confirm: false });
    } else {
      get_detail_info_by_ajax();
      get_detail_file_by_ajax();
    }
  }, []);

  const on_change_input = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let name = opt_obj.name;
    let value = opt_obj.value;
    if (name == "a_test_score") {
      value = strFunc.uncomma(value);
    }
    let new_detail_info = { ...detail_info };
    new_detail_info[name] = value;
    set_detail_info(new_detail_info);
  };

  const get_detail_info_by_ajax = () => {
    if (strFunc.is_empty(detail_info["a_seq"])) {
      return false;
    }
    let form_json_data: any = {
      "s_pri_arr": [map_detail_pri_val],
      "s_addon_study_map": "1",
      "s_addon_study_title": "1",
      "s_addon_user_info": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map_detail/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_detail_info(response.data["data"]["info_arr"][0]);
          } else {
            alert("데이터가 없습니다.");
            myProps.go_back_page();
          }
        } else {
          alert("데이터 얻는 중 오류.");
          myProps.go_back_page();
        }
      });
  };
  const get_detail_file_by_ajax = () => {
    let is_able_data = true;
    if (strFunc.is_empty(user.comp_seq)) {
      is_able_data = false;
    }
    if (map_detail_info == null) {
      is_able_data = false;
    } else {
      if (strFunc.is_empty(map_detail_info.a_seq)) {
        is_able_data = false;
      }
    }
    if (is_able_data == false) {
      set_detail_file_arr([]);
      return false;
    }

    let d_pri_val = map_detail_info["a_study_title_seq"] + "," + map_detail_info["a_stu_seq"] + "," + map_detail_info["a_seq"];
    let form_json_data: any = {
      "s_comp_seq": user.comp_seq,
      "s_par_id": "study_map_detail",
      "s_par_seq": d_pri_val,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_detail_file_arr(response.data["data"]["info_arr"]);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_save_map_detail_by_ajax = (inData: any) => {
    let opt_obj = {
      is_confirm: true,
      ...inData
    };
    if (opt_obj.is_confirm) {
      if (!confirm("저장하시겠습니까?")) {
        return false;
      }
    }

    let write_data_row: any = { ...detail_info };
    let now_ymdhis = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
    write_data_row["a_update_date"] = now_ymdhis;
    let form_json_data: any = {
      "data_arr": [write_data_row],
      "is_update_par_count": "1",
    };

    if (write_data_row["a_seq"] == "") {
      form_json_data["is_default_val"] = "1";
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map_detail/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          if (opt_obj.is_confirm) {
            alert("저장되었습니다.");
          }
          if (response.data["data"][0]) {
            set_detail_info({
              ...detail_info,
              ...response.data["data"][0]
            });
          }
          get_detail_file_by_ajax();
          myProps.callback_refresh_data();
          //myProps.go_back_page();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_success_map_detail_by_ajax = (inData: any) => {
    let opt_obj = {
      is_success: true,
      ...inData
    };
    let success_msg = "완료";
    if (!opt_obj.is_success) {
      success_msg = "미완료";
    }
    if (!confirm(success_msg + "처리 하시겠습니까?")) {
      return false;
    }

    let write_data_row: any = { ...detail_info };
    let now_ymdhis = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
    write_data_row["a_update_date"] = now_ymdhis;

    write_data_row["a_is_success"] = "1";
    write_data_row["a_success_date"] = now_ymdhis;
    if (!opt_obj.is_success) {
      write_data_row["a_is_success"] = "";
    }
    let form_json_data: any = {
      "data_arr": [write_data_row],
      "is_update_par_count": "1",
    };

    if (write_data_row["a_seq"] == "") {
      form_json_data["is_default_val"] = "1";
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map_detail/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          alert(success_msg + "처리 되었습니다.");
          if (response.data["data"][0]) {
            set_detail_info({
              ...detail_info,
              ...response.data["data"][0]
            });
          }
          myProps.callback_refresh_data();
          //myProps.go_back_page();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_delete_map_detail_by_ajax = () => {
    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }
    if (strFunc.is_empty(detail_info["a_study_title_seq"])
      || strFunc.is_empty(detail_info["a_stu_seq"])
      || strFunc.is_empty(detail_info["a_seq"])) {
      alert("키값이 없습니다.");
      return false;
    }
    let del_data_row: any = {
      a_study_title_seq: detail_info["a_study_title_seq"],
      a_stu_seq: detail_info["a_stu_seq"],
      a_seq: detail_info["a_seq"],
    };
    let form_json_data: any = {
      "data_arr": [del_data_row],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map_detail/delete', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          alert("삭제되었습니다.");
          myProps.callback_refresh_data();
          myProps.go_back_page();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  let stu_grade_str = "";
  if (detail_info.user_info) {
    for (let i = 0; i < select_a_stu_grade_option_arr.length; i++) {
      let tmp_select_opt = select_a_stu_grade_option_arr[i];
      if (detail_info.user_info["a_stu_grade"] == tmp_select_opt["value"]) {
        stu_grade_str = tmp_select_opt["text"];
      }
    }
  }

  return (
    <div className={Style.map_detail_wrap}>
      {myProps.is_view_top_info &&
        <div>
          <div className="text-center" style={{ fontSize: "15px" }}>
            {detail_info.user_info &&
              <span>
                {detail_info.user_info["a_user_name"]}
                <span className="ml-1" style={{ color: "gray" }}>
                  {strFunc.cut_str(detail_info.user_info["a_school_name"], 2, "")} {stu_grade_str}
                </span>
              </span>
            }
          </div>
          <div className={Style.top_info_wrap}>
            <span className={Style.top_info_span}>
              {detail_info.study_title}
            </span>
            <span className={Style.top_info_span}>
              {"▶"} {detail_info.a_section_name}
            </span>
            <span className={Style.top_info_span}>
              {"▶"} {detail_info.a_unit_name}
            </span>
          </div>
        </div>
      }
      <div className="mt-2">
        <ManageButtonArea
          detail_info={detail_info}
          go_save_map_detail_by_ajax={go_save_map_detail_by_ajax}
          go_success_map_detail_by_ajax={go_success_map_detail_by_ajax}
          go_delete_map_detail_by_ajax={go_delete_map_detail_by_ajax}
          go_back_page={myProps.go_back_page}
          back_btn_text={myProps.back_btn_text}
        ></ManageButtonArea>
      </div>
      <div className={Style.map_detail_title}>
        1. 읽고 녹음하기
      </div>
      <div className={Style.map_detail_con}>
        <FilePieceArea
          file_accept_sort="audio"
          file_arr={detail_file_arr}
          default_file_row_data={{
            ...default_file_row_data,
            a_sort1: "audio",
            a_sort2: "record",
          }}
          user_data={myProps.user_data}
          callback={get_detail_file_by_ajax}
        ></FilePieceArea>
        <FilePieceArea
          file_accept_sort="video"
          file_arr={detail_file_arr}
          default_file_row_data={{
            ...default_file_row_data,
            a_sort1: "video",
            a_sort2: "record",
          }}
          user_data={myProps.user_data}
          callback={get_detail_file_by_ajax}
        ></FilePieceArea>
        <TextAreaComponent
          name="a_audio_record_msg"
          value={detail_info["a_audio_record_msg"]}
          onChange={(e: any) => {
            on_change_input({ name: e.target.name, value: e.target.value });
          }}
          height={40}
          class_name={Style.map_input_textarea}
          style={{ width: "97%" }}
        ></TextAreaComponent>
      </div>
      <div className={Style.map_detail_title}>
        2. 모르는 단어 찾고 뜻 쓰기
      </div>
      <div className={Style.map_detail_con}>
        <FilePieceArea
          file_accept_sort="img"
          file_arr={detail_file_arr}
          default_file_row_data={{
            ...default_file_row_data,
            a_sort1: "img",
            a_sort2: "word",
          }}
          user_data={myProps.user_data}
          callback={get_detail_file_by_ajax}
        ></FilePieceArea>
        <TextAreaComponent
          name="a_research_word"
          value={detail_info["a_research_word"]}
          onChange={(e: any) => {
            on_change_input({ name: e.target.name, value: e.target.value });
          }}
          height={40}
          class_name={Style.map_input_textarea}
          style={{ width: "97%" }}
        ></TextAreaComponent>
      </div>
      <div className={Style.map_detail_title}>
        3. 개념정리
      </div>
      <div className={Style.map_detail_con}>
        <FilePieceArea
          file_accept_sort="img"
          file_arr={detail_file_arr}
          default_file_row_data={{
            ...default_file_row_data,
            a_sort1: "img",
            a_sort2: "concept",
          }}
          user_data={myProps.user_data}
          callback={get_detail_file_by_ajax}
        ></FilePieceArea>
        대단원
        <TextAreaComponent
          name="a_concept_section"
          value={detail_info["a_concept_section"]}
          onChange={(e: any) => {
            on_change_input({ name: e.target.name, value: e.target.value });
          }}
          height={40}
          class_name={Style.map_input_textarea}
          style={{ width: "97%" }}
        ></TextAreaComponent>
      </div>
      <div className={Style.map_detail_con}>
        소단원
        <TextAreaComponent
          name="a_concept_unit"
          value={detail_info["a_concept_unit"]}
          onChange={(e: any) => {
            on_change_input({ name: e.target.name, value: e.target.value });
          }}
          height={40}
          class_name={Style.map_input_textarea}
          style={{ width: "97%" }}
        ></TextAreaComponent>
      </div>
      <div className={Style.map_detail_title}>
        4. 코넬식 노트정리
      </div>
      <div className={Style.map_detail_con}>
        <FilePieceArea
          file_accept_sort="img"
          file_arr={detail_file_arr}
          default_file_row_data={{
            ...default_file_row_data,
            a_sort1: "img",
            a_sort2: "cornell_note",
          }}
          user_data={myProps.user_data}
          callback={get_detail_file_by_ajax}
        ></FilePieceArea>
        <TextAreaComponent
          name="a_cornell_note"
          value={detail_info["a_cornell_note"]}
          onChange={(e: any) => {
            on_change_input({ name: e.target.name, value: e.target.value });
          }}
          height={40}
          class_name={Style.map_input_textarea}
          style={{ width: "97%" }}
        ></TextAreaComponent>
      </div>
      <div className={Style.map_detail_title}>
        5. 내용을 5가지로 분류하여 집에가서도 붙여놓고 외우기
        <p>
          <span className={Style.map_detail_memo_span} style={{ background: "red" }}>정의-빨간색</span>
          <span className={Style.map_detail_memo_span} style={{ background: "yellow" }}>예시-노란색</span>
          <span className={Style.map_detail_memo_span} style={{ background: "green" }}>인과-초록색</span>
          <span className={Style.map_detail_memo_span} style={{ background: "#eee", color: "#000" }}>구분-흰색</span>
          <span className={Style.map_detail_memo_span} style={{ background: "blue" }}>비교와대조-파랑색</span>
        </p>
        <FilePieceArea
          file_accept_sort="img"
          file_arr={detail_file_arr}
          default_file_row_data={{
            ...default_file_row_data,
            a_sort1: "img",
            a_sort2: "memorize_span",
          }}
          user_data={myProps.user_data}
          callback={get_detail_file_by_ajax}
        ></FilePieceArea>
      </div>
      <div className={Style.map_detail_title}>
        6. 암기시간 - 하브루타 (선생님이 질문하고 학생은 안보고 답하고)
      </div>
      <div className={Style.map_detail_con}>
        <TextAreaComponent
          name="a_memorize"
          value={detail_info["a_memorize"]}
          onChange={(e: any) => {
            on_change_input({ name: e.target.name, value: e.target.value });
          }}
          height={40}
          class_name={Style.map_input_textarea}
          style={{ width: "97%" }}
        ></TextAreaComponent>
        <FilePieceArea
          file_accept_sort="img"
          file_arr={detail_file_arr}
          default_file_row_data={{
            ...default_file_row_data,
            a_sort1: "img",
            a_sort2: "memorize",
          }}
          user_data={myProps.user_data}
          callback={get_detail_file_by_ajax}
        ></FilePieceArea>
      </div>
      <div className={Style.map_detail_title}>
        7. 마인드맵으로 정리하기 (백지인출)
        <p>못하면 오픈북으로 나머지 정리하기</p>
      </div>
      <div className={Style.map_detail_con}>
        <TextAreaComponent
          name="a_mind_map"
          value={detail_info["a_mind_map"]}
          onChange={(e: any) => {
            on_change_input({ name: e.target.name, value: e.target.value });
          }}
          height={40}
          class_name={Style.map_input_textarea}
          style={{ width: "97%" }}
        ></TextAreaComponent>
        <FilePieceArea
          file_accept_sort="img"
          file_arr={detail_file_arr}
          default_file_row_data={{
            ...default_file_row_data,
            a_sort1: "img",
            a_sort2: "mind_map",
          }}
          user_data={myProps.user_data}
          callback={get_detail_file_by_ajax}
        ></FilePieceArea>
      </div>
      <div className={Style.map_detail_title}>
        8. 문제풀이 - 집에서 숙제해오기
      </div>
      <div className={Style.map_detail_con}>
        <TextAreaComponent
          name="a_matter_solve"
          value={detail_info["a_matter_solve"]}
          onChange={(e: any) => {
            on_change_input({ name: e.target.name, value: e.target.value });
          }}
          height={40}
          class_name={Style.map_input_textarea}
          style={{ width: "97%" }}
        ></TextAreaComponent>
        <FilePieceArea
          file_accept_sort="img"
          file_arr={detail_file_arr}
          default_file_row_data={{
            ...default_file_row_data,
            a_sort1: "img",
            a_sort2: "matter_solve",
          }}
          user_data={myProps.user_data}
          callback={get_detail_file_by_ajax}
        ></FilePieceArea>
      </div>
      <div className={Style.map_detail_title}>
        9. 채점 - 학원에서 선생님께 채점받기
      </div>
      <div className={Style.map_detail_con} style={{ textAlign: "center" }}>
        <input type="number" name="a_test_score" value={detail_info["a_test_score"]}
          onChange={(e: any) => {
            on_change_input({ name: e.target.name, value: e.target.value });
          }}
          onClick={(e: any) => { e.target.select(); }}
          style={{ width: "50px", textAlign: "center" }}
        /> 점
        <FilePieceArea
          file_accept_sort="img"
          file_arr={detail_file_arr}
          default_file_row_data={{
            ...default_file_row_data,
            a_sort1: "img",
            a_sort2: "test_score",
          }}
          user_data={myProps.user_data}
          callback={get_detail_file_by_ajax}
        ></FilePieceArea>
      </div>
      <div className={Style.map_detail_title}>
        10. 오답정리 - 학원에서 (문제, 답 모두쓰기)
      </div>
      <div className={Style.map_detail_con}>
        <TextAreaComponent
          name="a_wrong_answer"
          value={detail_info["a_wrong_answer"]}
          onChange={(e: any) => {
            on_change_input({ name: e.target.name, value: e.target.value });
          }}
          height={40}
          class_name={Style.map_input_textarea}
          style={{ width: "97%" }}
        ></TextAreaComponent>
        <FilePieceArea
          file_accept_sort="img"
          file_arr={detail_file_arr}
          default_file_row_data={{
            ...default_file_row_data,
            a_sort1: "img",
            a_sort2: "wrong_answer",
          }}
          user_data={myProps.user_data}
          callback={get_detail_file_by_ajax}
        ></FilePieceArea>
      </div>
      <ManageButtonArea
        detail_info={detail_info}
        go_save_map_detail_by_ajax={go_save_map_detail_by_ajax}
        go_success_map_detail_by_ajax={go_success_map_detail_by_ajax}
        go_delete_map_detail_by_ajax={go_delete_map_detail_by_ajax}
        go_back_page={myProps.go_back_page}
        back_btn_text={myProps.back_btn_text}
      ></ManageButtonArea>
    </div>
  );
};
export default WriteDetailArea;