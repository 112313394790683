
const RadioListArea=(props:any)=>{
  let myProps={
    valueTextArr:[],//[{"value":"","text":""}]
    value:"",
    name:"",
    handleInputChange:(e:any)=>{},
    ...props
  };
  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    myProps.handleInputChange(event);
  };

  let radiosTag=myProps.valueTextArr.map((item:any,idx:number)=>{
    let bChecked=false;
    
    let class_name_str="";
    if(idx!=0){
      class_name_str+=" ml-2";
    }
    if(item.value==myProps.value){
      bChecked=true;
      class_name_str+=" main_color";
    }
    return (
      <label key={idx} className={class_name_str} >
        <input type="radio" name={myProps.name} value={item.value} onChange={handleInputChange} checked={bChecked} className="mr-1" />
        {item.text}
      </label>
    );
  });

  return (
    <>
      {radiosTag}
    </>
  );
}

export default RadioListArea;