import { forwardRef, useEffect, useImperativeHandle, useRef,useState } from "react"
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import LoadingComponent from "@/pcomponents/common/content/loading";
import DeskComponent from "./desk";

const WriteRoomComponent = forwardRef((props:any, ref) => {
  const myProps={
    list:(inOptObj:any)=>{

    },
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const deskComponentRef=useRef<any>(null);

  const [isUpdate,setIsUpdate]=useState(false);
  const [rowData,setRowData]=useState({
    "a_seq":"",
    "a_corp_seq":user.corp_seq,
    "a_name":"",
    "a_size_x":5,
    "a_size_y":5,
    "a_location":"",
    "a_memo":"",
    "a_is_use":"1",
    "a_order_num":"",
  });
  const [loading_data,set_loading_data]=useState({
    "is_loading":false,
    "text":"로딩중.."
  });
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  if(isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }

  useEffect(()=>{
    
  },[]);
  
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowDataChild
  }));

  const setRowDataChild=(inRowData:any,inIsUpdate:boolean)=>{
    setRowData({
      ...rowData,
      ...inRowData
    });
    setIsUpdate(inIsUpdate);
    if(deskComponentRef.current!=null){
      deskComponentRef.current.setDeskXY({
        x:inRowData.a_size_x,
        y:inRowData.a_size_y
      });
      
      if(inRowData.desk_arr){
        deskComponentRef.current.setDeskArr(inRowData.desk_arr);
      }else{
        deskComponentRef.current.setDeskArr([]);
      }
    }
  };

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name=="a_size_x"){
      deskComponentRef.current.setDeskXY({
        x:value,
        y:rowData.a_size_y
      });
    }else if(name=="a_size_y"){
      deskComponentRef.current.setDeskXY({
        x:rowData.a_size_x,
        y:value
      });
    }else if(name=="a_order_num"){
      value=strFunc.comma(value);
    }

    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };

  const goWrite=(e:any) => {
    if(rowData.a_name==""){
      alert("강의실명 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let formJsonData={
      "room_info":rowData,
      "is_update":"",
      "is_add_desk":"1",
      "desk_arr":[],
    };
    if(deskComponentRef.current!=null){
      formJsonData["desk_arr"]=deskComponentRef.current.getDeskArr();
    }
    
    if(isUpdate){
      formJsonData["is_update"]="1";
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/room/write_one',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.list({});
        if(deskComponentRef.current!=null){
          response.data["data"]["info_arr"][0].desk_arr=deskComponentRef.current.getDeskArr();
        }
        setRowDataChild(response.data["data"]["info_arr"][0],true);
        set_loading_data({
          "is_loading":true,
          "text":"저장되었습니다."
        });
        setTimeout(()=>{
          set_loading_data({
            "is_loading":false,
            "text":"저장되었습니다."
          });
        },2000);
      }else{
        alert(response.data["msg"]);
      }
    });

  };

  let size_scale_xys=[];
  for(let i=1;i<=40;i++){
    size_scale_xys.push(i);
  }

  return (
    <div>
      <h4 style={{height:28,lineHeight:"28px"}}>
        강의실관리
      </h4>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="100px"></col>
            <col width="30%"></col>
            <col width="100px"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>강의실명</th>
              <td>
                <input type="text" className="row-input" name="a_name" value={rowData.a_name} onChange={handleInputChange} placeholder="강의실명" />
              </td>
              <th>위치</th>
              <td>
                <input type="text" className="row-input" name="a_location" value={rowData.a_location} onChange={handleInputChange} placeholder="위치" />
              </td>
            </tr>
            <tr>
              <th>상태</th>
              <td>
                <button className={"btn-m m-1 "+(rowData.a_is_use=="1"?"btn-yellowish2":"btn-line-yellowish2")} 
                  onClick={()=>{setRowData({...rowData,"a_is_use":"1"});}}>사용</button>
                <button className={"btn-m m-1 "+(rowData.a_is_use!="1"?"btn-yellowish2":"btn-line-yellowish2")} 
                  onClick={()=>{setRowData({...rowData,"a_is_use":""});}} >미사용</button>
              </td>
              <th>순번</th>
              <td>
                <input type="text" className="row-input" name="a_order_num" value={rowData.a_order_num} onChange={handleInputChange} placeholder="순번" />
              </td>
            </tr>
            <tr>
              <th>크기</th>
              <td>
                X:
                <select className="row-input" name="a_size_x" value={rowData.a_size_x} onChange={handleInputChange} 
                  style={{width:50}}>
                  {
                    size_scale_xys.map((item:any,idx:number)=>{
                      return (
                        <option value={item} key={idx}>{item}</option>
                      );
                    })
                  }
                </select>
                <span className="ml-2">
                  Y:
                  <select className="row-input" name="a_size_y" value={rowData.a_size_y} onChange={handleInputChange} 
                    style={{width:50}}>
                    {
                      size_scale_xys.map((item:any,idx:number)=>{
                        return (
                          <option value={item} key={idx}>{item}</option>
                        );
                      })
                    }
                  </select>
                </span>
              </td>
              <td colSpan={2}>
                <textarea className="row-input h-20" name="a_memo" value={rowData.a_memo} onChange={handleInputChange} placeholder="메모" ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-1">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
      </div>
      <div className="mt-3">
        <DeskComponent
          ref={deskComponentRef}
        ></DeskComponent>
      </div>
      {loading_data.is_loading&&
        <LoadingComponent text={loading_data.text}></LoadingComponent>
      }
    </div>
  );
});

export default WriteRoomComponent;