import strFunc from '@/lib/lyg/string';
function StudyArea(props: any) {
  const myProps = {
    study_info: {},
    study_idx: 0,
    studyXColumnArr: [],
    section_arr: [],
    study_arr: [],
    set_study_arr: (study_arr: any) => { },
    check_study_seq_arr: [],
    set_check_study_seq_arr: (inData: any) => { },
    onChangeKeyDown: (inData: any) => { },
    ...props
  };

  let study_info = myProps.study_info;
  let study_arr = myProps.study_arr;
  let section_arr = myProps.section_arr;

  const onChangeRowData = (inOptObj: any) => {
    if (inOptObj == undefined) { inOptObj = {}; }
    let optObj = {
      key: "",
      value: "",
      row_num: 0,
      ...inOptObj
    };
    let value = optObj["value"];
    let key = optObj["key"];
    let row_num = optObj["row_num"];
    if (key == "a_is_essential") {
      if (value) {
        value = "1";
      } else {
        value = "";
      }
    } else if (key == "a_amount_num") {
      value = strFunc.comma(value);
    }

    let tmp_study_arr: any = [...study_arr];
    tmp_study_arr[row_num][key] = value;

    myProps.set_study_arr(tmp_study_arr);
  };

  const removeRowData = (row_num: number) => {
    let tmp_study_arr = [];
    for (let i = 0; i < study_arr.length; i++) {
      if (i != row_num) {
        tmp_study_arr.push(study_arr[i]);
      }
    }
    myProps.set_study_arr(tmp_study_arr);
  };

  const moveOrderNum = (row_num: number, up_down: string) => {
    let tmp_study_arr: any = [...study_arr];

    let next_row_num = row_num;
    if (up_down == "up") {
      next_row_num = row_num - 1;
    } else if (up_down == "down") {
      next_row_num = row_num + 1;
    }

    if (next_row_num < 0) {
      return false;
    }
    if (next_row_num >= tmp_study_arr.length) {
      return false;
    }

    let now_row_data = tmp_study_arr[row_num];
    let change_row_data = tmp_study_arr[next_row_num];

    tmp_study_arr[row_num] = change_row_data;
    tmp_study_arr[next_row_num] = now_row_data;

    tmp_study_arr = getReSetOrderNum(tmp_study_arr);

    myProps.set_study_arr(tmp_study_arr);
  };

  const getReSetOrderNum = (tmp_study_arr: any) => {
    for (let i = 0; i < tmp_study_arr.length; i++) {
      tmp_study_arr[i].a_order_num = i + 1;
    }
    return tmp_study_arr;
  };

  const getColumnInputTag = (inOptObj: any) => {
    if (inOptObj == undefined) { inOptObj = {}; }
    let optObj = {
      "key": "",
      "data": {},
      "row_num": 0,
      "key_i": 0,
      ...inOptObj
    };
    let key = optObj["key"];
    let val_str = "";
    if (optObj["data"][key] != undefined) {
      val_str = optObj["data"][key];
    }

    let tag_obj = (
      <span>{val_str}</span>
    );
    if (key == "check_box") {
      let is_checked_row = false;
      if (strFunc.str_in_array(study_info["a_seq"], myProps.check_study_seq_arr) != -1) {
        is_checked_row = true;
      }
      tag_obj = (
        <input type="checkbox" value="1"
          onChange={(e: any) => {
            if (is_checked_row == false) {
              myProps.set_check_study_seq_arr([...myProps.check_study_seq_arr, study_info["a_seq"]]);
            } else {
              let tmp_check_arr = [];
              for (let i = 0; i < myProps.check_study_seq_arr.length; i++) {
                if (myProps.check_study_seq_arr[i] != study_info["a_seq"]) {
                  tmp_check_arr.push(myProps.check_study_seq_arr[i]);
                }
              }
              myProps.set_check_study_seq_arr(tmp_check_arr);
            }
          }}
          checked={is_checked_row}
        />
      );
    } else if (key == "a_section_seq") {
      let select_options = section_arr.map((section_info: any, section_idx: number) => {
        return (
          <option value={section_info.a_seq} key={section_idx}>{section_info.a_title}</option>
        );
      });
      tag_obj = (
        <select value={val_str} style={{ width: "98%" }}
          onChange={(e: any) => {
            onChangeRowData({
              key: key,
              value: e.target.value,
              row_num: optObj["row_num"],
            });
          }}
        >
          <option value="">선택없음</option>
          {select_options}
        </select>
      );
    } else if (key == "a_is_essential") {
      tag_obj = (
        <input type="checkbox" value="1" style={{ width: "98%" }}
          onChange={(e: any) => {
            onChangeRowData({
              key: key,
              value: e.target.checked,
              row_num: optObj["row_num"],
            });
          }}
          checked={val_str == "1"}
        />
      );
    } else if (key == "a_amount_sort") {
      tag_obj = (
        <select style={{ width: "98%", border: "1px solid gray" }}
          className="row_study_input"
          value={val_str}
          onChange={(e: any) => {
            onChangeRowData({
              key: key,
              value: e.target.value,
              row_num: optObj["row_num"],
            });
          }}
          onKeyDown={(e: any) => {
            if(e.keyCode==40||e.keyCode==38||e.keyCode==37||e.keyCode==39){
              return e?.preventDefault();
            }
          }}
          onKeyUp={(e: any) => {
            myProps.onChangeKeyDown({
              e: e,
            });
          }} >

          <option value="">선택</option>
          <option value="개">개</option>
          <option value="쪽">쪽</option>
          <option value="문제">문제</option>

        </select>
      );
    } else if (key == "a_memo") {
      tag_obj = (
        <textarea style={{ width: "98%", height: 30, border: "1px solid gray" }}
          value={val_str}
          onChange={(e: any) => {
            onChangeRowData({
              key: key,
              value: e.target.value,
              row_num: optObj["row_num"],
            });
          }}
        ></textarea>
      );
    } else if (key == "row_view_manage") {
      tag_obj = (
        <div>
          <button className="btn-s btn-gray" onClick={() => { moveOrderNum(optObj["row_num"], "up"); }}>▲</button>
          <button className="btn-s btn-gray ml-2" onClick={() => { moveOrderNum(optObj["row_num"], "down"); }}>▼</button>
          <button className="btn-s btn-red ml-2" onClick={() => { removeRowData(optObj["row_num"]); }}>X</button>
        </div>
      );
    } else if (key == "row_view_num") {
      tag_obj = (<span>{myProps.study_idx + 1}</span>);
    } else {
      tag_obj = (
        <input type="text" value={val_str} className="row_study_input" style={{ width: "98%" }}
          autoComplete='off'
          onChange={(e: any) => {
            onChangeRowData({
              key: key,
              value: e.target.value,
              row_num: optObj["row_num"],
            });
          }}
          onKeyUp={(e: any) => {
            myProps.onChangeKeyDown({
              e: e,
            });
          }}
        />
      );
    }

    return tag_obj;
  };

  let study_td_arr = myProps.studyXColumnArr.x_column_list_arr.map((column: any, column_idx: number) => {
    let tag_obj = getColumnInputTag({
      "key": column.key,
      "data": study_info,
      "row_num": study_info.row_num,
      "key_i": column_idx,
    });
    return (
      <td key={column_idx}>
        <div style={{ width: column["width"], textAlign: "center" }}>
          {tag_obj}
        </div>
      </td>
    );
  });

  return (
    <tr>
      {study_td_arr}
    </tr>
  );
}
export default StudyArea;