import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import strFunc from "@/lib/lyg/string";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useEffect, useState } from "react";

function SearchArea(props: any) {
  let myProps = {
    xColumnArr: {},
    listOpt: {},
    list: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;

  const [corse_list_opt, set_corse_list_opt] = useState({
    "s_category_seq":"",
    "is_need_count":"",
    "is_need_info_arr":"1",
    "is_no_limit":"1",
  });
  const [categoryInfoArr, setCategoryInfoArr] = useState([]);
  const [corse_info_arr,set_corse_info_arr] = useState([]);
  useEffect(() => {
    list_category();
    list_corse_by_ajax({});
  }, []);

  const list_category = ()=>{
    let category_form_json={
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/category/list',category_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setCategoryInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const list_corse_by_ajax=(inData:any)=>{
    let tmp_corse_list_opt={
      ...corse_list_opt,
      ...inData
    };
    set_corse_list_opt(tmp_corse_list_opt);
    if(strFunc.is_empty(tmp_corse_list_opt["s_category_seq"])){
      set_corse_info_arr([]);
      myProps.list({ "s_corse_seq": "", "now_page": 1 });
      return false;
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/corse/list',tmp_corse_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_corse_info_arr(response.data["data"]["info_arr"]);
        let tmp_corse_seq_arr=[];
        for(let i=0;i<response.data["data"]["info_arr"].length;i++){
          tmp_corse_seq_arr.push(response.data["data"]["info_arr"][i]["a_seq"]);
        }
        myProps.list({ "s_corse_seq": tmp_corse_seq_arr, "now_page": 1 });
      }else{
        
      }
    });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    myProps.list({ [name]: value, "now_page": 1 });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.list({ [key]: date_str, "now_page": 1 });
  };

  const go_prev_after = (sort: "prev" | "after") => {
    let selected_date = listOpt.s_start_date;
    if (strFunc.is_empty(selected_date)) {
      selected_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let change_date_str = "";
    if (sort == "prev") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(selected_date), 'day', -1), "Y-m-d");
    } else if (sort == "after") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(selected_date), 'day', +1), "Y-m-d");
    }
    myProps.list({
      "s_start_date": change_date_str,
      "s_end_date": change_date_str,
      "now_page": 1
    });
  };

  const go_today_search = () => {
    myProps.list({
      "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
      "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
      "now_page": 1
    });
  };
  const go_now_month_search = () => {
    myProps.list({
      "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-01"),
      "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-t"),
      "now_page": 1
    });
  };

  return (
    <div className="relative">
      <div className="search-box relative py-0" style={{ lineHeight: "20px" }}>
        <button className="btn btn-dark" onClick={() => { go_prev_after("prev"); }}>◀</button>
        <div className="search_date_picker_wrap">
          <DatePicker
            wrapperClassName=""
            selected={listOpt.s_start_date != "" ? new Date(listOpt.s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        ~
        <div className="search_date_picker_wrap">
          <DatePicker
            wrapperClassName="pl-1"
            selected={listOpt.s_end_date != "" ? new Date(listOpt.s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button className="btn btn-dark" onClick={() => { go_prev_after("after"); }}>▶</button>
        <button className="btn btn-gray" onClick={go_today_search} >오늘</button>
        <button className="btn btn-gray" onClick={go_now_month_search} >이번달</button>
        <select className="search-input_select" value={listOpt.s_is_success} name="s_is_success"
          onChange={handleInputChange} >
          <option value="">완료여부</option>
          <option value="1">예</option>
          <option value="empty">아니오</option>
        </select>
        <select className="search-input_select ml-1" value={corse_list_opt.s_category_seq} 
          style={{width:"100px"}}
          onChange={(e:any)=>{
            list_corse_by_ajax({
              "now_page":1,
              "s_category_seq":e.target.value
            });
          }}>
          <option value="">카테고리</option>
          {
            categoryInfoArr.map((item:any,idx:number)=>{
              return (
                <option key={idx} value={item.a_seq}>{item.a_title}</option>
              );
            })
          }
        </select>
        <select className="search-input_select ml-1" value={listOpt.s_corse_seq} 
          style={{width:"100px"}}
          onChange={(e:any)=>{
            myProps.list({ "s_corse_seq": e.target.value, "now_page": 1 });
          }}>
          <option value="">코스</option>
          {
            corse_info_arr.map((item:any,idx:number)=>{
              return (
                <option key={idx} value={item.a_seq}>{item.a_title}</option>
              );
            })
          }
        </select>
        <button className="btn btn-gray" onClick={() => { myProps.list({ "now_page": 1 }); }} >검색</button>
      </div>
    </div>
  );
};
export default SearchArea;