import CircleArea from "./circle";
import strFunc from "@/lib/lyg/string";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function TopInfoArea(props: any) {
  let myProps = {
    info: {},
    td_sort_data_arr: [],
    Style: {},
    openTodoDaySort: (inData: any) => { },
    show_detail_user_by_seq: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;

  const get_average_per_by_data = () => {
    let aver_per = 0;
    let per_sum_num = 0;
    let per_sum_count = 0;;
    for (let i = 0; i < myProps.td_sort_data_arr.length; i++) {
      if (myProps.td_sort_data_arr[i]["sort"] == "pre_homework") {
        continue;
      }
      let row_per_num = parseInt(strFunc.uncomma(myProps.td_sort_data_arr[i]["percent"]));
      per_sum_num += row_per_num;
      per_sum_count++;
    }
    if (per_sum_count != 0 && per_sum_num != 0) {
      aver_per = Math.floor(per_sum_num / per_sum_count);
    }
    return aver_per;
  };
  let aver_per = get_average_per_by_data();

  const get_color_style_of_sum_tag = (percent: number) => {
    let color = "#F48282";
    if (percent == 0) {
      color = "#F48282";
    } else if (percent > 0) {
      color = "#EBBD70";
    }
    if (percent >= 100) {
      color = "#B7C528";
    }
    return color;
  };
  let per_color = get_color_style_of_sum_tag(aver_per);

  let a_stu_grade_str = info.a_stu_grade;
  let select_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  for (let i = 0; i < select_option_arr.length; i++) {
    if (select_option_arr[i]["value"] == a_stu_grade_str) {
      a_stu_grade_str = select_option_arr[i]["text"];
    }
  }

  let stu_total_code=info.a_stu_total_code;

  return (
    <div className={Style.card_box_top_wrap}>
      <div 
        className={Style.card_box_top_per_wrap}
        onClick={() => {
        myProps.openTodoDaySort({
          select_stu_name: info.a_user_name,
          select_stu_seq: info.a_seq,
        });
      }} style={{cursor:"pointer"}}>
        <CircleArea
          per_num={aver_per}
          color={per_color}
          Style={Style}
          with={50}
          height={50}
        ></CircleArea>
        <div className={Style.card_box_top_per_text} style={{ color: per_color }}>
          {aver_per}%
        </div>
      </div>
      <div className={Style.card_box_top_title} onClick={() => {
        myProps.show_detail_user_by_seq(info.a_seq);
      }}>
        <div style={{fontSize:"16px"}}>
          {info.a_user_name}
          {stu_total_code&&
            <span className="ml-1" style={{fontSize:"12px",color:"#3FAC96"}}>{stu_total_code}</span>
          }
        </div>
        <div style={{color:"#666"}} title={info["a_school_name"]}>
          {strFunc.cut_str(info["a_school_name"],2,"")} {a_stu_grade_str}
        </div>
      </div>
    </div>
  );
}
export default TopInfoArea;