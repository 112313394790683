import LygFile from '@/lib/lyg/file';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FileSlideArea from "@/pages/comp/basic/file/popup/view_file_slide";
import { useState } from 'react';

function ViewFileListArea(props: any) {
  let myProps = {
    file_arr: [],
    default_file_row_data: {},
    Style: {},
    goDelete: (inData: any) => { },
    ...props
  };
  let file_arr = myProps.file_arr;
  let default_file_row_data = myProps.default_file_row_data;
  let Style = myProps.Style;
  
  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "view_img",

    select_file_idx: 0,
    img_file_arr: [],

    title: "사진보기",
    width: "100%",
    height: "100%",
    y: "0",
    is_border_radius: false,
  });

  const open_img_view_popup = (inData: any) => {
    let opt_obj={
      select_file_idx: 0,
      img_file_arr: [],
      ...inData
    };

    setPopupData({
      ...popupData,
      title: "사진보기",
      sort: "view_img",
      ...opt_obj,
      "isOpen": true
    });
  };

  const get_div_tags = () => {
    let img_file_arr:any = [];
    for (let i = 0; i < file_arr.length; i++) {
      let file_info = file_arr[i];
      if (file_info["a_sort1"] == default_file_row_data["a_sort1"]
        && file_info["a_sort2"] == default_file_row_data["a_sort2"]
        && file_info["a_sort3"] == default_file_row_data["a_sort3"]
      ) {
        img_file_arr.push(file_info);
      }
    }
    let img_div_tags = img_file_arr.map((item: any, idx: number) => {
      let file_url = item.a_serverurl + "/" + item.a_path + item.a_savename;
      let file_type = LygFile.get_file_type({ext:item.a_ext,file_type:item.a_type});
      let file_sort = LygFile.get_type_sort({ ext: item.a_ext,file_type:file_type, file_sort: "audio" });
      let is_image = item.is_image;
      let img_url = file_url;
      if (is_image == "1") {
        img_url = item.img_url;
      }
      return (
        <div key={idx} className={Style.file_form_item} title={item.a_oriname}>
          <div>
            {item.a_oriname}
          </div>
          {file_sort == "audio" &&
            <audio controls
            >
              <source src={file_url} type={file_type}></source>
              Your browser does not support the audio element.
            </audio>
          }
          {file_sort == "video" &&
            <video controls
            >
              <source src={file_url} type={file_type}></source>
              Your browser does not support the video tag.
            </video >
          }
          {file_sort == "img" &&
            <img src={img_url} alt={item.a_oriname} 
            onClick={()=>{
              open_img_view_popup({
                select_file_idx: idx,
                img_file_arr: img_file_arr
              });
            }} />
          }
          <button className="btn-s btn-red px-2" onClick={() => { myProps.goDelete(item); }}
            style={{ position: "absolute", right: 0, top: 0 }}>x</button>
        </div>
      );
    });

    return img_div_tags;
  };

  return (
    <div>
      <div>
        {get_div_tags()}
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius} >
          {popupData.sort === "view_img" &&
            <FileSlideArea
              file_info_arr={popupData.img_file_arr}
              select_file_idx={popupData.select_file_idx}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></FileSlideArea>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default ViewFileListArea;