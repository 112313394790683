import strFunc from "@/lib/lyg/string";
import CardBoxArea from "./card_box";
import Style from "./css/style.module.css";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import DateFunc from '@/lib/lyg/date_func';
import LygCalendarFunc from '@/lib/lyg/calendar';
import UserGroupDataFunc from "@/pcomponents/common/content/list/user/search/tab_area/func/group_data";

function CardListArea(props: any) {
  let myProps = {
    tab_id: "",
    info_arr: [],
    select_date: "",
    set_info_arr: (inData: any) => { },
    group_data_arr_ref: null,
    list: (inData: any) => { },
    check_row_val_arr: [],
    set_check_row_val_arr: (inData: any) => { },
    is_print_mode: false,
    is_show_etc_group: false,
    show_group_time:{},
    set_group_time:(inData: any) => { },
    able_time_arr_ref:null,
    xColumnArr: {},
    openAttendPopup: (inData: any) => { },
    deleteAttend: (inData: any) => { },
    open_write_td_data_popup: (inData: any) => { },
    openTodoDaySort: (inData: any) => { },
    open_stu_layer_popup: (inData: any) => { },
    ...props
  };
  let group_data_arr_ref=myProps.group_data_arr_ref;
  let select_stu_grade_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade2' });
  let select_homework_state_arr = XcolumnFunc.getSortByTableKey({ 'table': 'daily_report', 'key': 'a_homework_state' });
  let now_d_json = DateFunc.get_date_json(new Date());
  let select_date_json = DateFunc.get_date_json(new Date(myProps["select_date"]));
  let select_day_str = DateFunc.get_day_str_by_day_num(select_date_json.day);
  let cal_data=LygCalendarFunc.get_cal_data_by_select_date(myProps["select_date"]);
  
  let pre_date_change_num = -1;
  if (select_date_json.day == 1) {
    //일요일이면 3일뒤로이동(금요일부터 보이게한다.)
    pre_date_change_num = -3;
  }
  let pre_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(myProps["select_date"]), 'day', pre_date_change_num), "Y-m-d");

  if (myProps.is_print_mode) {
    for (let i = 0; i < myProps.xColumnArr.x_column_list_arr.length; i++) {
      if (myProps.xColumnArr.x_column_list_arr[i]["key"] == "row_view_checkbox") {
        myProps.xColumnArr.x_column_list_arr[i]["is_show"] = "";
      }
    }
  }

  let show_column_cnt = 0;
  for (let i = 0; i < myProps.xColumnArr.x_column_list_arr.length; i++) {
    if (myProps.xColumnArr.x_column_list_arr[i]["is_show"] == "1") {
      show_column_cnt++;
    }
  }

  const on_check_all = (e: any) => {
    if (e.target.checked == true) {
      //추가
      let tmp_check_row_val_arr = myProps.check_row_val_arr;
      for(let g_i=0;g_i<group_data_arr_ref.current.length;g_i++){
        let group_data=group_data_arr_ref.current[g_i];
        let tmp_lecture_seq="";
        if(group_data["lecture_info"]){
          tmp_lecture_seq=group_data["lecture_info"]["a_seq"];
        }
        for (let i = 0; i < myProps["info_arr"].length; i++) {
          let info = myProps["info_arr"][i];
          let tmp_card_row_pri_val=info["a_seq"]+","+tmp_lecture_seq;
          if (strFunc.str_in_array(tmp_card_row_pri_val, myProps.check_row_val_arr) == -1) {
            tmp_check_row_val_arr.push(tmp_card_row_pri_val);
          }
        }
      }
      
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    } else {
      //제거
      let tmp_check_row_val_arr: any = [];
      for(let g_i=0;g_i<group_data_arr_ref.current.length;g_i++){
        let group_data=group_data_arr_ref.current[g_i];
        let tmp_lecture_seq="";
        if(group_data["lecture_info"]){
          tmp_lecture_seq=group_data["lecture_info"]["a_seq"];
        }
        for (let i = 0; i < myProps["info_arr"].length; i++) {
          let info = myProps["info_arr"][i];
          let tmp_card_row_pri_val=info["a_seq"]+","+tmp_lecture_seq;
          if (strFunc.str_in_array(tmp_card_row_pri_val, myProps.check_row_val_arr) == -1) {
            tmp_check_row_val_arr.push(tmp_card_row_pri_val);
          }
        }
      }
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    }
  };

  const on_change_group_checkbox = (inData: any) => {
    let opt_obj = {
      checked: false,
      info_arr: [],
      lecture_info:null,
      ...inData,
    };
    if (strFunc.is_empty(opt_obj["info_arr"])) {
      return false;
    }
    let tmp_lecture_seq="";
    if(opt_obj["lecture_info"]){
      tmp_lecture_seq=opt_obj["lecture_info"]["a_seq"];
    }

    if (opt_obj["checked"]) {
      //추가
      let tmp_check_row_val_arr = myProps.check_row_val_arr;
      for (let i = 0; i < opt_obj["info_arr"].length; i++) {
        let info = opt_obj["info_arr"][i];
        let tmp_card_row_pri_val=info["a_seq"]+","+tmp_lecture_seq;
        if (strFunc.str_in_array(tmp_card_row_pri_val, myProps.check_row_val_arr) == -1) {
          tmp_check_row_val_arr.push(tmp_card_row_pri_val);
        }
      }
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    } else {
      //제거
      let tmp_check_row_val_arr: any = [];
      for (let i = 0; i < opt_obj["info_arr"].length; i++) {
        let info = opt_obj["info_arr"][i];
        let tmp_card_row_pri_val=info["a_seq"]+","+tmp_lecture_seq;
        if (strFunc.str_in_array(tmp_card_row_pri_val, myProps.check_row_val_arr) != -1) {

        } else {
          tmp_check_row_val_arr.push(tmp_card_row_pri_val);
        }
      }
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    }
  };

  const get_cardbox_tags = (inData: any) => {
    let opt_obj = {
      infoArr: [],
      first_td_tag: "",
      lecture_info: null,
      lecture_time_str: "",
      ...inData
    };
    let infoArr = opt_obj["infoArr"];
    let first_td_tag = opt_obj["first_td_tag"];
    let lecture_info = opt_obj["lecture_info"];
    let lecture_time_str = opt_obj["lecture_time_str"];
    let show_week_arr = get_show_week_arr();
    let cardbox_tags: any = infoArr.map((item: any, idx: number) => {
      return (
        <CardBoxArea
          key={idx}
          info={item}
          set_info={(info: any) => {
            let tmp_info_arr = myProps.info_arr;
            for (let i = 0; i < tmp_info_arr.length; i++) {
              if (tmp_info_arr[i]["a_seq"] == info["a_seq"]) {
                tmp_info_arr[i] = info;
              }
            }
            myProps.set_info_arr(tmp_info_arr);
          }}
          row_num={idx}
          first_td_tag={first_td_tag}
          lecture_info={lecture_info}
          select_date={myProps.select_date}
          pre_date_str={pre_date_str}
          list={myProps.list}
          Style={Style}
          show_column_cnt={show_column_cnt}
          show_week_arr={show_week_arr}
          lecture_time_str={lecture_time_str}
          check_row_val_arr={myProps.check_row_val_arr}
          set_check_row_val_arr={myProps.set_check_row_val_arr}
          is_print_mode={myProps.is_print_mode}
          select_stu_grade_arr={select_stu_grade_arr}
          select_homework_state_arr={select_homework_state_arr}
          openAttendPopup={myProps.openAttendPopup}
          deleteAttend={myProps.deleteAttend}
          open_write_td_data_popup={(inData:any)=>{
            //myProps.open_write_td_data_popup(inData);
          }}
          openTodoDaySort={myProps.openTodoDaySort}
          open_stu_layer_popup={myProps.open_stu_layer_popup}
        ></CardBoxArea>
      );
    });

    if (strFunc.is_empty(cardbox_tags)) {
      //cardbox_tags = "";
    }

    return cardbox_tags;
  };

  const set_group_time_data=(inData:any)=>{
    let opt_obj={
      group_data_arr:[],
      ...inData
    };
    let able_time_arr:any=[];
    let group_data_arr=opt_obj.group_data_arr;
    group_data_arr_ref.current=group_data_arr;
    for(let i=0;i<group_data_arr.length;i++){
      let group_data=group_data_arr[i];
      if(strFunc.str_in_array(group_data["start_time_str"],able_time_arr)==-1){
        able_time_arr.push(group_data["start_time_str"]);
      }
    }

    if(myProps.able_time_arr_ref){
      myProps.able_time_arr_ref.current=able_time_arr;
    }
  };

  const get_cardbox_group_tags = () => {
    let group_data_arr = UserGroupDataFunc.get_cardbox_group_data_arr({
      select_date: myProps.select_date,
      info_arr: myProps.info_arr,
      tab_id: myProps.tab_id,
    });
    set_group_time_data({group_data_arr:group_data_arr});

    let cardbox_group_tags = group_data_arr.map((group_info: any, idx: number) => {
      let group_title_str = group_info["title"];
      if (myProps.is_show_etc_group == false) {
        if (group_info["title"] == "기타") {
          return "";
        }
      }
      if(myProps.show_group_time.sort=="now"){
        if(group_info["end_time_str"]){
          let end_time_h=parseInt(strFunc.uncomma(group_info["end_time_str"].substring(0,2)));
          let now_h=parseInt(now_d_json.h);
          if(end_time_h<now_h){
            return "";
          }
        }
      }else if(myProps.show_group_time.sort=="select"){
        if(strFunc.str_in_array(group_info["start_time_str"],myProps.show_group_time.select_time_arr)==-1){
          return "";
        }
      }
      let lecture_info = group_info["lecture_info"];
      if (myProps.tab_id == "stu_grade") {
        let select_grade_obj_arr = strFunc.get_obj_by_key_val_at_obj_arr("value", group_title_str, select_stu_grade_arr);
        if (select_grade_obj_arr.length == 1) {
          group_title_str = select_grade_obj_arr[0]["text"];
        }
      }
      let first_row_span_num = group_info["info_arr"].length * 3;
      let first_td_tag = (
        <td rowSpan={first_row_span_num} title={group_title_str}>
          <div style={{ display: "inline-block" }}>
            <label>
              {myProps.is_print_mode==false &&
                <input type="checkbox" className="ml-1" onChange={(e: any) => {
                  on_change_group_checkbox({
                    checked: e.target.checked,
                    info_arr: group_info["info_arr"],
                    lecture_info: lecture_info
                  });
                }} />
              }
              <br />
              {group_title_str.substring(0, 5)}
              <br />
              ~
              <br />
              {group_title_str.substring(6, 11)}
              <br />
              {strFunc.cut_str(group_title_str.substring(11), 5, "")}
            </label>
          </div>
        </td>
      );
      if (first_row_span_num < (3 * 4)) {
        first_td_tag = (
          <td rowSpan={first_row_span_num} title={group_title_str}>
            <div style={{ display: "inline-block" }}>
              <label>
                {strFunc.cut_str(group_title_str.substring(11), 5, "")}
              </label>
            </div>
          </td>
        );
      }
      return (
        <tbody key={idx} >
          {get_cardbox_tags({
            infoArr: group_info["info_arr"],
            first_td_tag: first_td_tag,
            lecture_info: lecture_info,
            lecture_time_str: group_title_str.substring(0, 11),
          })}
        </tbody>
      );
    });

    if (strFunc.is_empty(cardbox_group_tags)) {
      cardbox_group_tags = (
        <tbody>
          <tr>
            <td colSpan={show_column_cnt}>
              <div className="text-center" style={{ lineHeight: "50px" }}>
                내용이 없습니다.
              </div>
            </td>
          </tr>
        </tbody>
      );
    }

    return cardbox_group_tags;
  };

  const get_show_week_arr = () => {
    let show_week_arr = [];
    let select_date_json = DateFunc.get_date_json(new Date(myProps["select_date"]));
    for (let i = 0; i < myProps.xColumnArr.x_column_list_arr.length; i++) {
      let row_xcolumn = myProps.xColumnArr.x_column_list_arr[i];
      if (row_xcolumn["key"].indexOf("row_view_week_") != -1
        && row_xcolumn["is_show"] == "1") {

        let row_day_num = parseInt(row_xcolumn["key"].replace("row_view_week_", ""));
        let change_day_diff_num = row_day_num - select_date_json.day;
        let week_date_str = DateFunc.get_date_format(
          DateFunc.get_change_date(new Date(myProps["select_date"]), 'day', change_day_diff_num)
          , "Y-m-d");
        row_xcolumn["row_day_num"] = row_day_num;
        row_xcolumn["change_day_diff_num"] = change_day_diff_num;
        row_xcolumn["week_date_str"] = week_date_str;

        show_week_arr.push(row_xcolumn);
      }
    }
    return show_week_arr;
  };
  let show_week_arr = get_show_week_arr();

  let wrap_table_style:any={
    minWidth:"1200px"
  };
  if(myProps.is_print_mode){
    wrap_table_style["padding"]=15;
  }

  return (
    <div className="mt-1" style={wrap_table_style}>
      <div style={{ height: "auto" }}>
        <div className={Style.report_table}>
          <table>
            <colgroup>
              {
                myProps.xColumnArr.x_column_list_arr.map((item: any, idx: number) => {
                  if (item["is_show"] != "1") {
                    return "";
                  }
                  let width_str=item["width"] + "%";
                  return (
                    <col key={idx} width={width_str}></col>
                  );
                })
              }
            </colgroup>
            <thead>
              <tr>
                <th colSpan={show_column_cnt}>
                  <div style={{ position: "relative", textAlign: "center" }}>
                    주간보고서
                    <span style={{ position: "absolute", right: 0, fontSize: 12 }}>
                      {myProps.select_date} {cal_data.select_month_week_i+"주차"} ({select_day_str})
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <th rowSpan={2}>시간</th>
                {myProps.is_print_mode==false &&
                  <th rowSpan={2}>
                    <input type="checkbox" onChange={on_check_all} />
                  </th>
                }
                <th rowSpan={2}>이름</th>
                <th rowSpan={2}>학습<br />숙제</th>
                <th rowSpan={2}>본교재</th>
                <th rowSpan={2}>부교재</th>
                <th rowSpan={2}>숙제</th>
                <th colSpan={show_week_arr.length + 1}>학습</th>
              </tr>
              <tr>
                {show_week_arr.map((item: any, idx: number) => {
                  return (
                    <th key={idx}>{item.name}</th>
                  );
                })}
                <th>달성/목표</th>
              </tr>
            </thead>
            {get_cardbox_group_tags()}
          </table>
        </div>
      </div>
    </div>
  );
};
export default CardListArea;