import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function ListTabArea(props: any) {
  let myProps = {
    list_tab: "day",//week,day
    set_list_tab: (inData: any) => { },
    ...props
  };
  let list_tab_arr = [
    { value: "week", text: "주별" },
    { value: "day", text: "일별" },
  ];

  return (
    <div style={{display:"flex",gap:"2px",justifyContent:"center"}}>
      <SelectBoxBar
        valueTextArr={list_tab_arr}
        value={myProps.list_tab}
        on_change={(inData: any) => {
          myProps.set_list_tab(inData.value);
        }}
        box_style={{minWidth:"50px"}}
      ></SelectBoxBar>
    </div>
  );
};
export default ListTabArea;