import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import strFunc from "@/lib/lyg/string";

function SearchArea(props: any) {
  let myProps = {
    listOpt: {
      "s_start_date": "",
      "s_end_date": "",
      "s_is_success": "",
    },
    list: (inData: any) => { },
    s_select_date: "",
    set_s_select_date: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });
  if (strFunc.is_empty(myProps.s_select_date)) {
    myProps.set_s_select_date(DateFunc.get_date_format(new Date(), "Y-m-d"));
  }

  const on_change_search_input = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let change_list_opt: any = {};
    change_list_opt[name] = value;
    myProps.list({
      now_page: "1",
      ...change_list_opt
    });
  };

  const goDateDayChange = (sort: "pre" | "next"|"now"|"this_month"|"pre_month") => {
    //next,pre
    let selected_date = myProps.s_select_date;
    if (selected_date == "") {
      selected_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let change_date_obj = new Date(selected_date);
    if (sort == "pre") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', -7);
    } else if (sort == "next") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', +7);
    }else if (sort == "now") {
      change_date_obj = new Date();
    }else if (sort == "this_month") {
      let tmp_change_date_str=DateFunc.get_date_format(new Date(),"Y-m-t");
      change_date_obj = new Date(tmp_change_date_str);
    }else if (sort == "pre_month") {
      let tmp_change_date_str=DateFunc.get_date_format(new Date(),"Y-m-01");
      change_date_obj = DateFunc.get_change_date(new Date(tmp_change_date_str), 'month', -1);
      tmp_change_date_str=DateFunc.get_date_format(change_date_obj,"Y-m-t");
      change_date_obj = new Date(tmp_change_date_str);
    }
    let change_date_str = DateFunc.get_date_format(change_date_obj, "Y-m-d");

    let start_date = DateFunc.get_date_format(DateFunc.get_change_date(new Date(change_date_str), 'day', -21), "Y-m-01");
    let end_date = DateFunc.get_date_format(change_date_obj, "Y-m-t");
    myProps.list({
      now_page: "1",
      s_start_date: start_date,
      s_end_date: end_date,
    });
    myProps.set_s_select_date(change_date_str);
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_list_opt:any={};
    if(key=="s_select_date"){
      let start_date = DateFunc.get_date_format(DateFunc.get_change_date(new Date(date_str), 'day', -21), "Y-m-01");
      let end_date = DateFunc.get_date_format(new Date(date_str), "Y-m-t");
      change_list_opt["s_start_date"]=start_date;
      change_list_opt["s_end_date"]=end_date;
      myProps.set_s_select_date(date_str);
    }
    myProps.list({ now_page: 1, [key]: date_str,...change_list_opt });
  };

  return (
    <div className="text-center">
      <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("pre"); }}>◀</button>
      <div style={{ display: "inline-block", width: 85 }}>
        <DatePicker
          wrapperClassName="pl-1"
          selected={myProps.s_select_date != "" ? new Date(myProps.s_select_date) : null}
          onChange={(date: Date) => {
            onChangeDatePicker("s_select_date", date);
          }}
          locale={ko}
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />
      </div>
      <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("next"); }}>▶</button>
      <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("pre_month"); }} >지난날</button>
      <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("this_month"); }}>이번달</button>
      <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("now"); }}>오늘</button>
      {/* <span className="ml-1">
        처방:
        <select className="search-input" name="s_is_success" value={listOpt.s_is_success}
          onChange={on_change_search_input}>
          <option value="">전체</option>
          <option value="1">완료</option>
          <option value="empty">미완료</option>
        </select>
      </span> */}
      <span className="ml-1">
        과목:
        <select className="search-input" name="s_subject" value={listOpt.s_subject}
          onChange={on_change_search_input}>
          <option value="">전체</option>
          {select_subject_option_arr.map((item:any,idx:number)=>{
            return (
              <option key={idx} value={item.value}>{item.text}</option>
            );
          })}
        </select>
      </span>
    </div>
  );
};
export default SearchArea;