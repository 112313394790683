import strFunc from "@/lib/lyg/string";
import { default_daily_report_last_info } from "@/pcomponents/common/content/edu/daily_report_last/data/default_data";

function DailyLastArea(props: any) {
  let myProps = {
    last_daily_report: {},
    ...props
  };
  let last_daily_report = {
    ...default_daily_report_last_info,
    ...myProps.last_daily_report
  };

  let text_book_study_style: any = {
    position: "relative",
    paddingLeft: "16px",
  };
  let text_book_sub_style: any = {
    position: "absolute",
    left: 0,
    top: 0,
    display: "inline-block",
    width: "16px",
    textAlign: "center",
    border: "1px solid #ddd",
    height: "100%",
  };

  return (
    <div className="write_table_base">
      <table style={{borderRadius:"0px"}}>
        <colgroup>
          <col width="10%" />
          <col width="40%" />
          <col width="10%" />
          <col width="40%" />
        </colgroup>
        <tbody>
          <tr>
            <th>본</th>
            <td>
              {last_daily_report["a_textbook"]}
            </td>
            <th>부</th>
            <td>
              {last_daily_report["a_subtextbook"]}
            </td>
          </tr>
          <tr>
            <th>대</th>
            <td>
              <div style={text_book_study_style}>
                {!strFunc.is_empty(last_daily_report["a_textbook_study_num"]) &&
                  <div style={text_book_sub_style}>
                    {last_daily_report["a_textbook_study_num"]}
                  </div>
                }
                {last_daily_report["a_textbook_study"]}
              </div>
            </td>
            <th>소</th>
            <td>
              <div style={text_book_study_style}>
                {!strFunc.is_empty(last_daily_report["a_textbook_study_det_num"]) &&
                  <div style={text_book_sub_style}>
                    {last_daily_report["a_textbook_study_det_num"]}
                  </div>
                }
                {last_daily_report["a_textbook_study_det"]}
              </div>
            </td>
          </tr>
          <tr>
            <th>숙제</th>
            <td>
              {last_daily_report["a_homework"]}
            </td>
            <th>학습량</th>
            <td>
              {last_daily_report["a_study_amount_memo"]}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default DailyLastArea;